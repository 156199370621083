.header-image {
  width: 100%;
  margin-bottom: -5px;
}

@media only screen and (min-width: 600px) {
  .content-padding {
     padding: 45px 45px 0 45px;
  }
}

@media only screen and (max-width: 600px) {
  .content-padding {
     padding: 0;
  }
}
